


















































































































































































































































































.framePage {
  .framePage-body {
    padding: 0 0.675rem;
    background: none;

    .shell {
      display: flex;
      height: 100%;

      .tt {
        margin: 10px 0;
        font-weight: 600;
      }

      .l {
        width: calc(100% - 300px);

        .el-row.row_1 {
          background: #fff;
          border-radius: 10px;

          .el-col {
            border: 1px solid #f9f9f9;
            text-align: center;
            padding: 20px 0;

            .l_1 {
              width: 40px;
              transition: transform .4s;
            }

            .l_2 {
              font-weight: 600;
              margin-top: 10px;
            }

            .l_3 {
              font-size: 12px;
              color: #999999;
              width: 120px;
              margin: auto;
              margin-top: 5px;
            }

            &:hover {
              cursor: pointer;

              .l_1 {
                transform: scale(1.3);
              }
            }
          }
        }

        .el-row.row_2 {
          background: #fff;
          border-radius: 10px;
          padding: 20px 0 0 0;
          margin-left: 0 !important;
          margin-right: 0 !important;

          .el-col {
            margin-bottom: 20px;

            >div {
              border-radius: 6px;
              padding: 20px;

              .l_2 {
                font-size: 24px;
                margin-top: 10px;
              }
            }
          }

          .col_1 {
            background: #F5FAFF;

            .l_2 {
              color: #43A1FF;
            }
          }

          .col_2 {
            background: #FFF9F0;

            .l_2 {
              color: #FFC171;
            }
          }

          .col_3 {
            background: #FFF5F0;

            .l_2 {
              color: #F99E6E;
            }
          }

          .col_4 {
            background: #EEFAF5;

            .l_2 {
              color: #57CF9F;
            }
          }

          .col_5 {
            background: #F2F1FD;

            .l_2 {
              color: #837BEC;
            }
          }

          .col_6 {
            background: #EFF4FF;

            .l_2 {
              color: #6893F8;
            }
          }
        }

        .row_3 {
          .row_3_shell {
            background: #FFF1F1;
            border: 1px solid #FF7474;
            color: #FF8787;
            padding: 6px 8px;
            margin-bottom: 10px;
            border-radius: 6px;
            display: flex;

            .row_3_shell_1 {
              width: 14px;
              margin-right: 8px;
            }
          }
        }
      }

      .r {
        width: 280px;
        margin-left: 20px;

        .r_1 {
          background: #fff;
          border-radius: 10px;
          padding: 20px;

          .r_1_1 {
            span {
              font-weight: 600;
              margin-bottom: 10px;
            }
          }

          .r_1_2 {
            span {
              font-size: 12px;
              color: #43A1FF;
              cursor: pointer;
            }
          }

          .r_1_3 {
            display: block;
            margin: auto;
          }

          .r_1_4 {
            font-size: 12px;
            color: #43A1FF;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
