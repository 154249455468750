.framePage .framePage-body {
  padding: 0 0.675rem;
  background: none;
}
.framePage .framePage-body .shell {
  display: flex;
  height: 100%;
}
.framePage .framePage-body .shell .tt {
  margin: 10px 0;
  font-weight: 600;
}
.framePage .framePage-body .shell .l {
  width: calc(100% - 300px);
}
.framePage .framePage-body .shell .l .el-row.row_1 {
  background: #fff;
  border-radius: 10px;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col {
  border: 1px solid #f9f9f9;
  text-align: center;
  padding: 20px 0;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col .l_1 {
  width: 40px;
  transition: transform .4s;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col .l_2 {
  font-weight: 600;
  margin-top: 10px;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col .l_3 {
  font-size: 12px;
  color: #999999;
  width: 120px;
  margin: auto;
  margin-top: 5px;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col:hover {
  cursor: pointer;
}
.framePage .framePage-body .shell .l .el-row.row_1 .el-col:hover .l_1 {
  transform: scale(1.3);
}
.framePage .framePage-body .shell .l .el-row.row_2 {
  background: #fff;
  border-radius: 10px;
  padding: 20px 0 0 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framePage .framePage-body .shell .l .el-row.row_2 .el-col {
  margin-bottom: 20px;
}
.framePage .framePage-body .shell .l .el-row.row_2 .el-col > div {
  border-radius: 6px;
  padding: 20px;
}
.framePage .framePage-body .shell .l .el-row.row_2 .el-col > div .l_2 {
  font-size: 24px;
  margin-top: 10px;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_1 {
  background: #F5FAFF;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_1 .l_2 {
  color: #43A1FF;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_2 {
  background: #FFF9F0;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_2 .l_2 {
  color: #FFC171;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_3 {
  background: #FFF5F0;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_3 .l_2 {
  color: #F99E6E;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_4 {
  background: #EEFAF5;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_4 .l_2 {
  color: #57CF9F;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_5 {
  background: #F2F1FD;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_5 .l_2 {
  color: #837BEC;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_6 {
  background: #EFF4FF;
}
.framePage .framePage-body .shell .l .el-row.row_2 .col_6 .l_2 {
  color: #6893F8;
}
.framePage .framePage-body .shell .l .row_3 .row_3_shell {
  background: #FFF1F1;
  border: 1px solid #FF7474;
  color: #FF8787;
  padding: 6px 8px;
  margin-bottom: 10px;
  border-radius: 6px;
  display: flex;
}
.framePage .framePage-body .shell .l .row_3 .row_3_shell .row_3_shell_1 {
  width: 14px;
  margin-right: 8px;
}
.framePage .framePage-body .shell .r {
  width: 280px;
  margin-left: 20px;
}
.framePage .framePage-body .shell .r .r_1 {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}
.framePage .framePage-body .shell .r .r_1 .r_1_1 span {
  font-weight: 600;
  margin-bottom: 10px;
}
.framePage .framePage-body .shell .r .r_1 .r_1_2 span {
  font-size: 12px;
  color: #43A1FF;
  cursor: pointer;
}
.framePage .framePage-body .shell .r .r_1 .r_1_3 {
  display: block;
  margin: auto;
}
.framePage .framePage-body .shell .r .r_1 .r_1_4 {
  font-size: 12px;
  color: #43A1FF;
  text-align: center;
  cursor: pointer;
}
